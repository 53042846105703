<template lang="pug">
.distance-measurment-control-panel-overlay
  distance-measurement-control-panel.ml-auto.mr-right-panel
</template>

<script>
import DistanceMeasurementControlPanel from "./components/DistanceMeasurementControlPanel";

export default {
  name: "DistanceMeasurement",

  components: {
    DistanceMeasurementControlPanel,
  },
};
</script>

<style scoped>
.distance-measurment-control-panel-overlay {
  z-index: 1;
  position: fixed;
  bottom: 0px;
  right: 0px;
  display: inline;
  pointer-events: none;
  
}

.mr-right-panel {
  margin-right: calc(var(--right-panel-width) + 8px);
}
</style>