<template lang='pug'>
  div.distance-measurement-controls-panel.px-2
    div.distance-measurement-controls-panel-header.d-flex.align-center.py-2
      div {{ $t('viewer.distanceMeasurement.controlPanel.title') }}
      v-icon.ml-2(:color='iconColor' rel='button' @click='openSettingsEdit') settings
      v-icon.ml-auto(:color='iconColor' rel='button' @click='close()') mdi-close-box-outline
    v-divider

    div.distance-measurement-controls-panel-header.d-flex.align-center.justify-space-between
      div {{ $t('viewer.distanceMeasurement.controlPanel.intersections') + ' (Ctrl)' }}
      v-switch( v-model='intersectionsDetect' light style='margin:0px;padding:0px;' color='accent' dense @click='switchOnIntersections()' )
    v-divider

    div.distance-measurement-panel-views.d-flex.flex-column.mt-2
      div.d-flex(v-for='distanceMeasurement in distanceMeasurements' :key='distanceMeasurement.id' :title='distanceMeasurement.length')
        div.d-flex.align-center
          v-tooltip( left )
            template( v-slot:activator='{ on, attrs }' )
              span( v-bind='attrs' v-on='on' :style='distanceNumStyle(distanceMeasurement)' ) {{ distanceMeasurementLength(distanceMeasurement) }}
            span {{ getDistanceMeasurementFullSize(distanceMeasurement) }}
          v-icon.ml-3( @click.stop='hideDistanceMeasurement(distanceMeasurement)' small ) {{ distanceMeasurement.visible ? 'mdi-eye' : 'mdi-eye-off' }}
          v-icon.ml-2( @click.stop='removeDistanceMeasurement(distanceMeasurement)' small ) mdi-close
    v-divider.mt-1(v-if="distanceMeasurements.length")

    div.d-flex.align-center.justify-space-between.my-2
      v-btn(:color='iconColor' icon @click='hideAllDistanceMeasurement()')
        v-icon {{ isHidden ? 'mdi-eye-off' : 'mdi-eye' }}
        
      v-btn(:color='iconColor' icon @click='removeAllDistanceMeasurements()')
        v-icon mdi-trash-can

    app-dialog( v-model="dialog.measurementSettings" width="340" :header="$t('viewer.distanceMeasurement.measurementSettings')" @close="dialog.measurementSettings = false" )
      div.overflow-hidden
        div.distance-measurement-controls-panel-header.d-flex.align-center
          div {{ $t('viewer.distanceMeasurement.measurementUnit') }}
          v-select.ml-4( v-model="editMeasurementSettings.units" :label="$t('viewer.distanceMeasurement.measurementUnit')" :items="unitsOfMeasurement"
            :item-text="option => $t('viewer.distanceMeasurement.measurementUnits.' + option.title)" dense solo flat return-object
            :background-color="backgroundColor" style="height: 40px; width: 30%" density="compact" )

        div.mt-3.distance-measurement-controls-panel-header.d-flex.align-center
          div {{ $t('viewer.distanceMeasurement.decimalPlacesCount') }}
          v-text-field.ml-4( v-model.number="editMeasurementSettings.decimalPlaces" type="number" :label="$t('viewer.distanceMeasurement.decimalPlacesCount')"
            dense solo flat :background-color="backgroundColor" style="height: 40px; width: 30%" :rules="limits" :min="0" :max="9" )

        .d-flex.justify-end.mt-8
          app-button( @click="updateMeasurementSetting" ) {{ $t('button.save') }}
        

</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'

export default {
  name: "DistanceMeasurementControlsPanel",

  data: () => ({
    isHidden: false,
    intersectionsDetect: false,
    ctrlKeyUp: null,
    decimalPlacesRules: [
      v => ( v < 10 ) || this.$t("viewer.distanceMeasurement.controlPanel.limitMax"),
      v => ( v > -1 ) || this.$t("viewer.distanceMeasurement.controlPanel.limitMin"),
    ],
    measurementSettings: {
      units: {
        title: "m",
        value: 1
      },
      decimalPlaces: 2,
    },
    
    dialog: {
      measurementSettings: false
    },

    editMeasurementSettings: {
      units: {
        title: "m",
        value: 1
      },
      decimalPlaces: 2,
    },
  }),

  mounted() {
    this.ctrlKeyUp = () => { }
    document.addEventListener("keyup", this.ctrlKeyUp = (event) => {
      if (event.key === "Control") {
        this.intersectionsDetect = !this.intersectionsDetect
        XeokitMediator.DistanceMeasurement.setCollisionDetectActive(this.intersectionsDetect)
      }
    })

    this.getUnitsOfMeasurement()
    
    if(this.user){
      this.measurementSettings = XeokitMediator.DistanceMeasurement.distanceMeasurementNumberSettings
      if(!this.measurementSettings) {
        this.updateRulerSettings(this.getDefaultRulerSettings).then(() => {
          this.measurementSettings = XeokitMediator.DistanceMeasurement.distanceMeasurementNumberSettings
        })
      }
    }
  },

  computed: {
    ...mapState('distanceMeasurement', ['unitsOfMeasurement']),
    ...mapState('authUser', ['user']),
    ...mapGetters('distanceMeasurement', ['getDefaultRulerSettings']),

    distanceMeasurements() {
      return XeokitMediator.DistanceMeasurement.distanceMeasurements
    },

    limits() {
      return [
      v => ( v < 10 ) || this.$t("viewer.distanceMeasurement.controlPanel.limitMax"),
      v => ( v > -1 ) || this.$t("viewer.distanceMeasurement.controlPanel.limitMin"),
    ]
    },

    iconColor() {
      return 'var(--v-surface-lighten3)'
    },

    backgroundColor() {
      return 'var(--v-surface-lighten1)'
    },
  },

  methods: {
    ...mapActions('distanceMeasurement', ['updateRulerSettings', 'getUnitsOfMeasurement']),

    hideDistanceMeasurement(distanceMeasurement) {
      XeokitMediator.DistanceMeasurement.setDistanceMeasurementsVisible([distanceMeasurement], !distanceMeasurement.visible)
    },

    hideAllDistanceMeasurement() {
      XeokitMediator.DistanceMeasurement.setDistanceMeasurementsVisible(this.distanceMeasurements, this.isHidden)
      this.isHidden = !this.isHidden
    },

    removeDistanceMeasurement(distanceMeasurement) {
      XeokitMediator.DistanceMeasurement.removeDistanceMeasurements([distanceMeasurement])
    },

    removeAllDistanceMeasurements() {
      XeokitMediator.DistanceMeasurement.removeDistanceMeasurements(this.distanceMeasurements)
    },

    switchOnIntersections() {
      XeokitMediator.DistanceMeasurement.setCollisionDetectActive(this.intersectionsDetect)
    },

    getDistanceMeasurementFullSize(distanceMeasurement) {
      let decimalPlaces = this.measurementSettings.decimalPlaces
      let xLength = distanceMeasurement.xLength.toFixed(decimalPlaces)
      let yLength = distanceMeasurement.yLength.toFixed(decimalPlaces)
      let zLength = distanceMeasurement.zLength.toFixed(decimalPlaces)

      let x = xLength != 0 ? "X~" + xLength + this.measurementSettings.units.title : ""
      let y = yLength != 0 ? "Y~" + yLength + this.measurementSettings.units.title : ""
      let z = zLength != 0 ? "Z~" + zLength + this.measurementSettings.units.title : ""

      return x + " " + y + " " + z
    },

    distanceMeasurementLength(distanceMeasurement) {
      let x = distanceMeasurement.xLength.toFixed(this.measurementSettings.decimalPlaces)
      let y = distanceMeasurement.yLength.toFixed(this.measurementSettings.decimalPlaces)
      let z = distanceMeasurement.zLength.toFixed(this.measurementSettings.decimalPlaces)

      let axis = ""
      if (x != 0 && y == 0 && z == 0) {
        axis = "X: " 
      }
      else if (x == 0 && y != 0 && z == 0) {
        axis = "Y: "
      }
      else if (x == 0 && y == 0 && z != 0) {
        axis = "Z: "
      }
      else {
        axis = "--- "
      }

      let length = (distanceMeasurement.length * this.measurementSettings.units.value).toFixed(this.measurementSettings.decimalPlaces)

      return axis + length + this.$t('viewer.distanceMeasurement.measurementUnitsShort.' + this.measurementSettings.units.title)
    },

    distanceNumStyle(distanceMeasurement) {
      return "color:" + "#3B93AF" + ";" +
      (distanceMeasurement.isAutoGenerated ? "text-decoration: underline;" : "")
    },

    updateMeasurementSetting() {
      if (this.editMeasurementSettings.decimalPlaces >= 0 && this.editMeasurementSettings.decimalPlaces <= 9) {
        this.dialog.measurementSettings = false

        this.measurementSettings = this.editMeasurementSettings
        this.updateRulerSettings(this.measurementSettings).then(() => {
          XeokitMediator.DistanceMeasurement.updateAllDistanceMeasurement()
        })
      }
    },

    openSettingsEdit() {
      this.editMeasurementSettings = Object.assign({}, this.measurementSettings)
      this.dialog.measurementSettings = true
    },

    close() {
      XeokitMediator.RegimeMeasurement.deactivate()
    },
  },

  beforeDestroy() {
    document.removeEventListener("keyup", this.ctrlKeyUp)
  }
}


</script>

<style lang='scss' scoped>
  .distance-measurement-controls-panel {
    min-width: 250px;
    max-width: 350px;

    display: flex;
    flex-direction: column;
    pointer-events: all;

    background: var(--v-surface-base);
  }

  .distance-measurement-controls-panel-header {
    color: var(--v-surface-lighten3)
  }

  .distance-measurement-panel-views {
    max-height: 40vh;
    overflow: auto;
  }
</style>