import { math } from "@xeokit/xeokit-sdk"
import {XeokitMediator} from "@/plugins/xeokit/XeokitMediator";

let mouseclicked

var SectionPlanes = {
  methods: {
    createSectionPlane () {
      this.contentOutSectionPlane = !this.contentOutSectionPlane
      this.activeMeasurments = false
      
      this.viewer.scene.camera.ortho.far = 2000
      this.viewer.scene.camera.ortho.near = 0

      if (this.contentOutSectionPlane)
        this.mouseClickedSectionPlane()
      else
        this.viewer.scene.input.off(mouseclicked)
    },

    mouseClickedSectionPlane() {
      mouseclicked = this.viewer.scene.input.on("mouseclicked", (coords) => {
        var pickResult = XeokitMediator.ScenePick.pickResult({
          canvasPos: coords,
          pickSurface: true,
        });

        if (pickResult && pickResult.worldNormal) {
          const sectionPlane = this.sectionPlanesPlugin.createSectionPlane({
              pos: pickResult.worldPos,
              dir: math.mulVec3Scalar([pickResult.worldNormal[0], -pickResult.worldNormal[2], pickResult.worldNormal[1]], -1)
          })
          
          this.sectionPlanesPlugin.showControl(sectionPlane.id);

          let section = {name: this.$t("viewer.sectionPlane.created") + new Date().toLocaleTimeString(), id: sectionPlane.id, active: true, visibility: true}
          let list = this.sectionList && this.sectionList.length > 0 ? this.sectionList.map(item => {
            item.active = false
            return item
          }) : []
          this.setSectionList(list)
          this.setItemSectionList(section)

          this.contentOutSectionPlane = false
          this.viewer.scene.input.off(mouseclicked)
        }
      })
    },

    destroySectionPlanes () {
      this.$store.state.viewerSectionPlane.sectionList.forEach(plane => {
        this.sectionPlanesPlugin.destroySectionPlane(plane.id)
      })

      this.$store.commit('viewerSectionPlane/clearSectionList')
      //this.clearSectionList()
    },

    deleteSectionPlane (section) {
      this.sectionPlanesPlugin.destroySectionPlane(section.id)
      let list = this.sectionList.filter(item => item.id !== section.id)
      this.setSectionList(list)
    },

    showControlSectionPlane (section) {
      this.sectionPlanesPlugin.hideControl()
      let list = this.sectionList.map(item => {
        if (item.id === section.id && !item.active) {
          item.active = true
          item.visibility = true
          this.sectionPlanesPlugin.showControl(item.id)
          this.sectionPlanesPlugin.sectionPlanes[section.id].active = section.visibility
        } else {
          item.active = false
        }
        return item
      })
      this.setSectionList(list)
    },

    flipSectionPlane(id) {
      this.sectionPlanesPlugin.sectionPlanes[id].flipDir()
    },

    visibilitySectionPlane(section) {
      this.sectionPlanesPlugin.hideControl()
      let list = this.sectionList.map(item => {
        if (item.id === section.id && !item.active && section.visibility) {
          item.active = true
          this.sectionPlanesPlugin.showControl(item.id)
        } 
        else {
          item.active = false
        }
        this.sectionPlanesPlugin.sectionPlanes[section.id].active = section.visibility
        return item
      })
      this.setSectionList(list)
    }
  }
}

export { SectionPlanes }