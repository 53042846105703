<template lang="pug">
  div
    v-tooltip( right )
      template( v-slot:activator="{ on, $attrs }" )
        div.button.d-flex.align-center.justify-center( :class="{['app-theme--' + themeName]: true, 'active': selected}" v-on="on")
          v-icon.style(:size="size" v-bind="$attrs" @click.native="$emit('click')" @mousedown.left="$emit('mousedown')" @mouseup.left="$emit('mouseup')" v-on="on" :color="color" test-id="left-panel-btn" ) {{ icon }}
      span
        slot
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    selected: Boolean,
    icon: {
      type: String,
      required: true
    },
    size: {
      type: [Number, String],
      default: 24
    },
    theme: {
      type: String,
      default: "light"
    },
    color: {
      type: String,
      default: "var(--v-primary-darken2)"
    }
  },

  computed: {
    ...mapGetters('theme', ['themeName']),
  }
}
</script>

<style lang="scss" scoped>
.button {
  border-radius: 4px;
  width: 32px;
  height: 32px;
  background-color:  var(--v-surface-base);
  cursor: pointer;
  &:hover {
    background-color: var(--v-surface-darken3);
    opacity: 0.7;
    // border: 1px solid rgb(117, 117, 117, .9);
  }
}

.active {
  background-color: var(--v-surface-darken3);
  // opacity: 0.4;
  // border: 1px solid #C4C4C4;
}
</style>